export class SwiperZoom {

    /**
     * SwiperZoom constructor
     * @param ingSwiper
     */
    constructor(ingSwiper) {
        this.ingSwiper = ingSwiper;
        this.containerZoom = document.createElement('div');
        this.closeZoom = document.createElement('div');
        this.containerLegend = document.createElement('div');
        this.isZoomActive = false
    }

    showZoom({thumbnailParams}) {
        if (this.isZoomActive) return false;

        this.containerZoom.classList.add('swiper-container-zoom');
        this.closeZoom.classList.add('swiper-close-zoom');
        this.containerLegend.classList.add('swiper-container-legend');
        if (this.ingSwiper.isMediaUnique) this.containerZoom.classList.add('swiper-container-media-uniq');

        document.body.appendChild(this.containerZoom);
        this.containerZoom.appendChild(this.closeZoom);
        this.ingSwiper.sliderContainer.appendChild(this.containerLegend);

        this.closeZoom.addEventListener('click', () => this.hideZoom());

        while (this.ingSwiper.el.children.length) {
            this.containerZoom.appendChild(this.ingSwiper.el.children[0]);
        }

        if (this.ingSwiper.thumbnailSlider) {
            this.ingSwiper.thumbnailSlider.params = {
                ...this.ingSwiper.thumbnailSlider.params,
                ...thumbnailParams
            }
        }

        this.ingSwiper.slider.update();
        if (this.ingSwiper.thumbnailSlider) this.ingSwiper.thumbnailSlider.update();

        this.defineLegend();

        this.isZoomActive = true;
    }

    hideZoom() {
        if (!this.isZoomActive) return false;
        this.containerZoom = document.querySelector('.swiper-container-zoom');

        this.containerZoom.removeChild(this.closeZoom);
        this.ingSwiper.sliderContainer.removeChild(this.containerLegend);

        while (this.containerZoom.children.length) {
            this.ingSwiper.el.appendChild(this.containerZoom.children[0]);
        }
        document.body.removeChild(this.containerZoom);

        this.ingSwiper.slider.update();
        if (this.ingSwiper.thumbnailSlider) {
            this.ingSwiper.resetThumbnailParams();
        }

        this.isZoomActive = false;
    }

    defineLegend() {
        const currentSlide = this.ingSwiper.slider.slides[this.ingSwiper.slider.activeIndex]
        const image = currentSlide.querySelector('img');
        this.containerLegend.innerHTML = image.title;
    }
}