import {default as Slider} from 'swiper'
import {SwiperZoom} from "./SwiperZoom";

import "swiper/css/swiper.min.css";
import "./SwiperClient.css";

export class SwiperClient {

    static defaultThumbnailParams = (that) => ({
        slidesPerView: 8,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        on: {
            init: function () {
                let lastSlide = this.slides[this.slides.length - 1];
                let x = lastSlide.offsetLeft + lastSlide.offsetWidth;
                let isOut = x > that.thumbnail.offsetWidth;
                if (!isOut) {
                    let pager = document.getElementById('thumb-pager');
                    pager.classList.add('is-smaller-than-container');
                }
            },
        },
        breakpoints: {
            320: {
                slidesPerView: 4
            },
            480: {
                slidesPerView: 5
            },
            640: {
                slidesPerView: 6
            },
            1100: {
                slidesPerView: 10
            }
        }
    })

    /**
     * SwiperClient constructor
     * @param selector
     * @param params
     * @param initialSelector
     * @param thumbnailSelector
     * @param style
     * @param isZoomEnable
     */
    constructor({selector, initialSelector, thumbnailSelector, isZoomEnable, params = {}}) {
        this.el = document.querySelector(selector);
        this.initialSelector = initialSelector;
        this.params = params;
        this.thumbnail = document.querySelector(thumbnailSelector);
        this.sliderContainer = this.el.querySelector('.swiper-container');
        this.isMediaUnique = this.el.classList.contains('media_unique');
        this.isZoomEnable = params.zoom?.enable ?? isZoomEnable;
        this.zoom = new SwiperZoom(this);
        this.loadedSlidesIndex = [];
    }

    init() {
        if (this.thumbnail && document.body.offsetWidth > 480) this.thumbnailSlider = this.getThumbnailSlider();
        this.slider = this.getSlider();

        this.slider.on('slidePrevTransitionStart', () => this.checkSlide(-1));
        this.slider.on('slideNextTransitionStart', () => this.checkSlide(1));
        this.checkSlide();

        if (this.isZoomEnable) {
            this.slider.on('slideChange', (e) => {
                if (this.zoom.isZoomActive) this.zoom.defineLegend();
            });
            this.slider.on('click', (e) => {
                if (e.target.tagName === 'IMG')  this.zoom.showZoom({
                    thumbnailParams: SwiperClient.defaultThumbnailParams(this)
                });
            });
        }
    }

    checkSlide() {
        let checkIndex = this.clampIndex(this.slider.activeIndex - 1);
        this.loadSlide(checkIndex);
        let nextCheckIndex = this.clampIndex(checkIndex + 1);
        this.loadSlide(nextCheckIndex);
        let prevCheckIndex = this.clampIndex(checkIndex - 1);
        this.loadSlide(prevCheckIndex);
    }

    loadSlide(index) {
        if (this.loadedSlidesIndex.indexOf(index) !== -1) return;
        this.loadedSlidesIndex.push(index);

        const slides = Array.from(this.slider.slides).filter(slide => !slide.classList.contains('swiper-slide-duplicate'));
        let checkSlide = slides[index];
        if (!checkSlide) return;
        let checkImage = checkSlide.querySelector('img');
        let currentDataSrc = checkImage.getAttribute('data-src');
        if (currentDataSrc) {
            Array.from(this.slider.slides).map(slide => {
                checkImage = slide.querySelector('img');
                let dataSrc = checkImage.getAttribute('data-src');
                if (dataSrc === currentDataSrc) {
                    checkImage.src = dataSrc;
                    checkImage.removeAttribute('data-src');
                }
            })
        }
    }

    clampIndex(index) {
        const slides = Array.from(this.slider.slides).filter(slide => !slide.classList.contains('swiper-slide-duplicate'));
        if (index === -1) index = slides.length - 1;
        if (index === slides.length) index = 0;
        return index
    }

    getSlider() {
        let parameters = {
            allowTouchMove: false
        };
        if (!this.isMediaUnique) {
            parameters = {
                pagination: {
                    el: this.el.querySelector('.swiper-pagination'),
                    type: 'fraction',
                },
                loop: true,
                // Navigation arrows
                navigation: {
                    nextEl: this.el.querySelector('.swiper-button-next'),
                    prevEl: this.el.querySelector('.swiper-button-prev'),
                },
                thumbs: {
                    swiper: this.thumbnailSlider
                },
                ...this.params.slider ?? {}
            }
        }

        return new Slider(this.initialSelector, parameters);
    }

    resetThumbnailParams() {
        this.thumbnailSlider.params = {
            ...this.thumbnailSlider.params,
            ...SwiperClient.defaultThumbnailParams(this),
            ...this.params.thumbnail ?? {}
        };
        this.thumbnailSlider.update();
    }

    getThumbnailSlider() {
        const params = {...SwiperClient.defaultThumbnailParams(this), ...this.params.thumbnail ?? {}};
        return new Slider('.swiper-container-thumbs', params)
    }
}